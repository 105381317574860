<template>
    <section>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="page-header">
                            <h3 v-if="!editableSectionTitle" class="page-title">
                                {{sectionTitle}}
                                <span class="badge badge-outline-primary badge-pill pointer" @click="editableSectionTitle = !editableSectionTitle"><i class="fa fa-pencil"></i></span>
                            </h3>
                            <b-form-group v-else>
                                <b-input-group>
                                    <b-form-input placeholder="Username" v-model="sectionTitle"></b-form-input>
                                    <b-input-group-text slot="append" class="bg-primary text-white pointer" @click="editableSectionTitle = !editableSectionTitle">
                                    <span><i class="fa fa-save"></i></span>
                                    </b-input-group-text>
                                </b-input-group>
                            </b-form-group>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0);">Accueil</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Carousel</li>
                                </ol>
                            </nav>
                        </div>

                        <!-- choix des types de données à ajouter -->
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                De quelle manière voulez vous utiliser ce Card?
                            </div>
                            <div class="col-md-6">
                                <h6>Créer des cards</h6> 
                                <button v-if="!collectionChecked" class="btn btn-gradient-success btn-icon-text">
                                    <i class="mdi mdi-check btn-icon-prepend"></i>
                                    Card
                                </button>
                                <button v-else @click="switchCollection()" class="btn btn-gradient-dark btn-icon-text">
                                    Card
                                </button>
                                <p class="text-muted mt-3">Cette méthode consiste à créer un par un tous les cards et leurs contenus. Vous aurez plus de choix sur la conception graphique ( Taille des textes, alignements ... )</p>
                            </div>
                            <div class="col-md-6">
                                <h6>Créer une collection de card</h6>
                                <button v-if="collectionChecked" class="btn btn-gradient-success btn-icon-text">
                                    <i class="mdi mdi-check btn-icon-prepend"></i>
                                    Collection
                                </button>
                                <button v-else class="btn btn-gradient-dark btn-icon-text" @click="switchCollection()">
                                    Collection
                                </button>
                                <!-- <b-button disabled class="btn btn-gradient-dark btn-fw" style="cursor:not-allowed">Collection</b-button> -->
                                <p class="text-muted mt-3">Cette méthode consiste à combiner une 'entité' avec un card. Ceux-ci seront générés automatiquement. Il sera impossible de modifier les contenus du card. Exemple: cards de produits</p>
                            </div>
                        </div>

                        <!-- création d'une collection -->
                        <div class="row" v-if="collectionChecked">
                            <div class="col-md-6"></div>
                            <div class="col-md-6">
                                <div class="card card-statistics social-card card-default">
                                    <div class="card-header">
                                        <h3 class="card-title">Créer une collection</h3>
                                    </div>
                                    <div class="card-body">
                                        <b-form-group label="Choisir l'entité à combiner avec le card"  label-for="entitytype">
                                            <b-form-select id="entitytype" v-model="selectedEntitytype" :options="entitytypes" value-field="entitytype" text-field="entitytype" placeholder="Sélectionnez l'entité à associer" size="lg" >
                                            </b-form-select>
                                        </b-form-group>
                                        <!-- titre -->
                                        <b-form-group label="Titre du card"  label-for="entitytypeTitle">
                                            <b-form-select id="entitytypeTitle" v-model="formCollection.selectedCardTitle" :options="formCollection.entitytypesKeys" placeholder="Sélectionnez l'entité à associer" size="lg" >
                                            </b-form-select>
                                        </b-form-group>
                                        <!-- prix -->
                                        <b-form-group label="prix affiché"  label-for="entitytypePrice">
                                            <b-form-select id="entitytypePrice" v-model="formCollection.selectedCardPrice" :options="formCollection.entitytypesKeys" placeholder="Sélectionnez l'entité à associer" size="lg" >
                                            </b-form-select>
                                        </b-form-group>
                                        <!-- description -->
                                        <b-form-group label="Description"  label-for="entitytypesKeys">
                                            <b-form-select id="entitytype" v-model="formCollection.selectedCardDescription" :options="formCollection.entitytypesKeys" placeholder="Sélectionnez l'entité à associer" size="lg" >
                                            </b-form-select>
                                        </b-form-group>
                                        <div class="d-flex justify-content-end">
                                            <button class="btn btn-link">Annuler</button>
                                            <button class="btn btn-gradient-primary" @click="createCollection()"> <i class="fa fa-plus"></i> Créer la Collection</button>
                                        </div>
                                    </div>
                                </div>
                            </div> <!-- col-md-6 -->
                        </div>

                        <!-- Création individuel de card -->
                        <div v-else class="row">
                            <div class="col-md-6">
                                <div class="card card-statistics social-card card-default">
                                    <div class="card-header">
                                        <h3 class="card-title">Créer individuellement les cards</h3>
                                    </div>
                                    <div class="card-body">
                                        <!-- Importation d'image du card -->
                                        <b-form-group label="Importer une image"  label-for="cardImage">
                                            <b-form-file v-model="file" id="inpu8" :state="Boolean(file)" @change="onFileSelected" placeholder="Choisir ou glisser l'image ici"></b-form-file>
                                        </b-form-group>
                                        <p>selected File: {{file ? file.name : ''}}</p>
                                        <!-- Titre -->
                                        <b-form-group label="Titre du card"  label-for="cardTitle">
                                            <b-form-input v-model="formIndividualCard.cardTitle" placeholder="Entrer le titre" ></b-form-input>
                                        </b-form-group>
                                        <!-- Prix -->
                                        <b-form-group label="Prix affiché"  label-for="cardPrice">
                                            <b-form-input v-model="formIndividualCard.cardPrice" placeholder="Entrer le prix"></b-form-input>
                                        </b-form-group>
                                        <!-- Description -->
                                        <b-form-group label="Description"  label-for="description">
                                            <b-form-input v-model="formIndividualCard.cardDescription" placeholder="Entrer la description"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="card-footer d-flex justify-content-end">
                                        <button class="btn btn-link">Annuler</button>
                                        <button class="btn btn-gradient-primary" @click="submitImageAndCard()"> <i class="fa fa-plus"></i> Ajouter le card</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <hr>
                            <h3 class="card-title">Format d'affichage des cards</h3>
                            <p class="text-muted">Choisissez le mode d'affichage des cards</p>
                            <div class="form-check form-check-primary">
                                <b-form-checkbox
                                id="scrollcards"
                                v-model="scrollcards"
                                name="scrollcards"
                                >
                                Scrollable horizontalement
                                </b-form-checkbox>
                            </div>
                            <hr>
                        </div>
                        
                        <!-- représentation des cards -->
                        <div class="row mt-4">
                            <div class="col-md-12">
                                <p><br></p>
                                <span v-for= "block in blocks" :key="block.id">
                                    <card-title-description v-if="collectionChecked" :key="collectionComponentKey" :entitytype="block.entitytype" :scrollcards="scrollcards" :title="block.data.collection['card-title']" :price="block.data.collection['card-price']" :description="block.data.collection['card-description']" column="col-md-3" sectionTitle="Représentation de la collection"></card-title-description>
                                    <card-title-description
                                    v-else
                                    :card="block.data.card"
                                    :scrollcards="scrollcards"
                                    :key="individualCardComponentKey"
                                    title="card-title"
                                    description="card-description"
                                    sectionTitle="Représentation des cards individuels"
                                    v-on:edit="editCard"
                                    editable>
                                    </card-title-description>
                                </span>
                            </div>
                        </div>

                        
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-link">Retour</button>
                            <button class="btn btn-gradient-primary" @click="saveCard()"> <i class="fa fa-save"></i> Enregistrer le card</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import cardTitleDescription from '../../../../components/element/card-title-description.vue'
export default {
    name: 'add-section-card-title-description',
    components: {
        cardTitleDescription
    },
    data () {
        return {
            collectionChecked: false,
            sectionTitle: '',
            section: '',
            editSectionTitle: false,
            editableSectionTitle:false,
            entitytypes: [],
            selectedEntitytype: '',
            formCollection: {
                submitEntitytype:'',
                entitytypesKeys:['Veuillez sélectionner une entité'],
                selectedCardTitle:'',
                selectedCardDescription:''
            },
            formIndividualCard: {
                cardTitle: '',
                cardPrice: '',
                cardDescription: ''
            },
            blocks: [],
            scrollcards:false,
            collectionComponentKey: 0,
            individualCardComponentKey: 0,
            file: null
        }
    },
    watch: {
         selectedEntitytype (value) {
            // this.getEntitytypesInfo
            // console.log('keys2', this.selectedEntitytypesInfo)
            console.log('value',value);
             axios.get(`https://sites.comolapapaya.com/${this.selectedEntitytype}`)
            .then ( resGetEntitypesInfo => {
                //éléments à submiter 
                this.formCollection.submitEntitytype= resGetEntitypesInfo.data.result
                console.log('submit', this.formCollection.submitEntitytype)

                this.formCollection.entitytypesKeys= Object.keys(resGetEntitypesInfo.data.result[0])
                console.log('keys', this.formCollection.entitytypesKeys)
                // console.log('entityTypesInfo', resGetEntitypesInfo)
            })
            .catch (errG => console.log('errorG',errG))
        },
        selectedEntitytypesInfo(value) {
            console.log('value entity',value);
        }
    },
    methods: {
        getSectionInfo (ulid) {
            axios.get(`sections/${ulid}`)
            .then (resSectionInfo => {
                console.log({resSectionInfo})
                this.section = resSectionInfo.data
                this.blocks = resSectionInfo.data.blocks
                console.log('blocks dans getSectionInfo', this.blocks);
                this.sectionTitle = resSectionInfo.data.title
                // condition d'affichage entre individuel et collection
                resSectionInfo.data.blocks[0].entitytype?this.collectionChecked=true:false
                //affichage scrollable horizontalement ou pas
                this.scrollcards=resSectionInfo.data.blocks[0].data.scrollcards
                console.log('section in getSectionInfo', this.section)
                //générer les données dans les formes si les cards est une collection
                })
            .catch (errSectionInfo => console.log({errSectionInfo}))
        },
        getEntitytypes () {
            axios.get('entitytypes')
            .then(resGetEntitytypes => {
                console.log(resGetEntitytypes)
                this.entitytypes = resGetEntitytypes.data.result
                })
            .catch(errGetEntitytypes => console.log(errGetEntitytypes))
        },
        createCollection () {
            this.blocks = []
            let blocks = this.blocks
            blocks.push({
                "element": "card",
                "entitytype": this.selectedEntitytype,
                data: {
                    "scrollcards": this.scrollcards,
                    collection: {
                        "card-title": this.formCollection.selectedCardTitle,
                        "card-price": this.formCollection.selectedCardPrice
                    },
                    card : [] //pas nécessaire mais pour repère
                }
            })
            this.blocks = blocks
            console.log(this.blocks);
            this.collectionComponentKey += 1
            this.saveCard()
        },
        addCard (imageContent) {
            console.log('this section in addCard',this.section);
            let card = this.blocks[0].data.card
            console.log('this.blocks',this.blocks);
            card.push({
                "card-image-url": imageContent,
                "card-title": this.formIndividualCard.cardTitle,
                "card-price": this.formIndividualCard.cardPrice,
                "card-description": this.formIndividualCard.cardDescription
            })
            this.blocks = [
                {
                    "element" : "card",
                    "data": {
                        "scrollcards": this.scrollcards,
                        "card": card
                    }
                }
            ]
            console.log('const card: ', card)
            console.log('blocks après add Card', this.blocks);
            this.individualCardComponentKey += 1
            this.saveCard()
        },
        saveCard () {
            axios.put(`sections/${this.ulid}`, {
                "title": this.sectionTitle,
                "blocks" : this.blocks
            },
            {
                headers: {
                    'X-AUTH-TOKEN': localStorage.getItem('token')
                }
            })
            .then (resSaveCard => {
                this.$snotify.success('Card enregistré avec succès', {
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                })
                console.log({resSaveCard})
                })
            .catch (errSaveCard => console.log(errSaveCard))
        },
        switchCollection() {
            this.$swal({
                title: 'Voulez vous changer de méthode de création?',
                text: 'Toutes les données de card de cette section seront effacées',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Valider',
                cancelButtonText: 'Annuler',
            })
            .then((result) => {
                if (result.value) {
                !this.collectionChecked
                ?this.$swal(
                    'Collection!',
                    'En quelques clics, générez votre card en combinant votre card à une entité',
                    'success',
                    this.collectionChecked = !this.collectionChecked,
                    
                    console.log('this.section',this.section),
                    this.blocks= [
                        {
                            entitytype: "",
                            data: {
                                scrollcards: true,
                                collection: {
                                    "card-title": ''
                                },
                                card: []
                            }
                        }
                    ],
                    this.collectionComponentKey += 1,
                    this.individualCardComponentKey += 1
                )
                :this.$swal(
                    'Card individuel!',
                    'Créez votre propre card selon votre goût',
                    'success',
                    this.collectionChecked = !this.collectionChecked,
                    console.log('this.section',this.section),
                    this.blocks = [
                        {
                            entitytype: "",
                            data: {
                                scrollcards: true,
                                collection: {
                                    "card-title" : ""
                                },
                                card : []
                            }
                        }
                    ],
                    this.collectionComponentKey += 1,
                    this.individualCardComponentKey += 1
                )
                }
            })
        },
        editCard(item) {
            console.log('payload',item)
        },
        // upload d'image
        onFileSelected (event) {
          this.file=event.target.files[0]
        },
        submitImageAndCard () {
          const fd = new FormData
          fd.append ('imgfile', this.file)
          fd.append ('alt', this.file)
          axios.post('imgs', fd, 
          {
            headers: {
              'X-AUTH-TOKEN': localStorage.getItem('token'),
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(resSubmitImage=> {
            console.log('resSubmitImage',resSubmitImage)
            let imageUrl = resSubmitImage.data.url
            this.addCard(imageUrl)
            })
          .catch(errSubmitImage=> console.log(errSubmitImage))
        }
    },
    mounted () {
        this.ulid = this.$route.params.ulid
        this.getSectionInfo(this.ulid)
        this.getEntitytypes()
    }
}
</script>